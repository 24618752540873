@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
	text-align: center;
	/* font-family: 'Courier New', Courier, monospace; */
	font-family: "Josefin Sans", cursive;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.page-odd {
	background-color: darkgray;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	margin: 0 !important;
	padding: 0 !important;
	width: 100vw;
}

.page-even {
	background-color: coral;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	margin: 0 !important;
	padding: 0 !important;
	width: 100vw;
}

.headshot {
	height: 200px;
	width: 200px;
	-webkit-animation: fadeInUp 1s linear 5s 1 normal both;
	        animation: fadeInUp 1s linear 5s 1 normal both;
}

.down-arrow {
	-webkit-animation: bounce infinite 2s;
	        animation: bounce infinite 2s;
}

.main-footer {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	font-size: larger;
}

.page-header {
	font-size: larger;
	font-weight: bold;
	left: 0;
	right: 0;
	bottom: 0;
}

.nav {
	position: relative;
	z-index: 1000;
	color: #fff;
}

.container {
	z-index: -1;
}

div,
.nav {
	margin-right: 0 !important;
	margin-left: 0 !important;
	padding: 0 !important;
	width: 100%;
}

.grid-header {
	font-weight: bold;
	font-size: larger;
	/* background-color: orange; */
}

.skills-head {
	margin: 2vh;
	/* height: 3vh; */
	font-weight: bolder;
	font-size: calc(10px + 3vmin);
}

.projs-head {
	margin: 2vh;
	font-weight: bolder;
	font-size: calc(10px + 3vmin);
}

.icon-link {
	font-size: 300%;
	bottom: 30;
	left: 0;
	right: 0;
	position: absolute;
	align-self: center;
	align-content: center;
}

#icon-circle-back {
	background-image: radial-gradient(
		circle at 50% 40%,
		rgb(255, 255, 255) 60%,
		rgba(255, 255, 2555, 0) 10%,
		rgba(255, 255, 2555, 0)
	);
	font-size: larger;
}

#icon-rec-back {
	font-size: larger;
	background-image: radial-gradient(
		circle at 50% 40%,
		rgb(255, 255, 255) 45%,
		rgba(255, 255, 2555, 0) 10%,
		rgba(255, 255, 2555, 0)
	);
}

i {
	color: orange;
	font-size: larger;
}

.skills {
	background-color: white;
	border-radius: 25px;
	/* padding: 20vw; */
	/* margin: 20vw; */
	width: 80vw;
	box-shadow: 15px 15px 20px -5px gray;
	align-self: center;
	top: 0;
}

.skills-con {
	width: 80vw;
}

.skills-row {
	color: black;
	width: 80vw;
}

.skills-col {
	font-size: calc(10px + 1.5vmin);
}

.skills-col:not(:last-child) {
	border-right: 1px solid;
	border-color: #2c2a4a;
}

ul {
	list-style: none;
	padding-left: 0;
}
​ li {
	list-style-type: "";
}

.about-me {
	/* background-color: white; */
	border-radius: 25px;
	width: 80vw;
	height: 80vh;
	vertical-align: middle;
	/* box-shadow: 0 8px 8px -4px whitesmoke; */
}

.proj {
	align-self: center;
	top: 0;
	width: 80vw;
}

.proj-con {
	width: 80vw;
	vertical-align: middle;
}

.proj-row {
	color: black;
	width: 80vw;
	vertical-align: middle;
	align-items: center;
	/* backdrop-filter: blur(5px); */
}

.proj-desc {
	font-size: calc(10px + 1.5vmin);
	color: #fff;
	/* backdrop-filter: blur(5px); */
	font-weight: normal;
}

.about-row {
	color: black;
	width: 80vw;
	height: calc(80vh / 3 * 2);
	vertical-align: middle;
	align-items: center;
	align-content: center;
}

.about-row:last-child {
	color: black;
	width: 80vw;
	height: calc(80vh / 3);
	vertical-align: middle;
	align-items: center;
	align-content: center;
}

.proj-col {
	font-size: calc(10px + 1.5vmin);
	vertical-align: middle;
	align-content: center;
}

.proj-col:nth-child(2) {
	border-right: 1px solid;
	border-left: 1px solid;
	border-color: lightskyblue;
}

.project {
	background-color: cornflowerblue;
	border-radius: 2vw;
	height: 50vh;
	/* line-height: calc(23vh - 1.5vmin - 10px); */
	padding-top: 100px;
	border: 1vw solid coral;
	overflow: hidden;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	align-self: center;
	vertical-align: middle;
	color: transparent;
	font-weight: bold;
	align-self: center;
	text-align: center;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	/* backdrop-filter: blur(5px); */
	/* display: flex; */
	align-items: center;
	min-width: calc(80vw / 3);
}

.project:hover > .project-title {
	/* backdrop-filter: blur(5px); */
}

.project:hover,
.project-link:hover {
	color: #fff;
	/* backdrop-filter: blur(5px); */
	justify-content: center;
	text-decoration: none;
	align-content: center;
	justify-content: center;
}

.project1 {
	background-image: url(/static/media/elena.99007e78.png);
}

.project2 {
	background-image: url(/static/media/API.2da9653a.png);
}

.project3 {
	background-image: url(/static/media/person-fill-check.ac3a6ee9.svg);
	background-color: transparent;
	background-size: contain;
}

.project-title {
	font-weight: 600;
	margin: 0;
}

.project-link {
	color: #fff!important;
	min-height: 50vh;
}

.project1:hover {
	background-image: url("https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png");
}

.project2:hover {
	background-image: url("https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png");
}

.about-sec {
	/* background-color: cornflowerblue; */
	border-radius: 2vw;
	height: inherit;
	font-size: calc(0.5vw + 2vh);
	/* line-height: calc(23vh - 1.5vmin - 10px); */
	padding-top: 100px;
	border: 1vw solid coral;
	/* border-bottom: ; */
	overflow: visible;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	align-self: center;
	vertical-align: middle;
	font-weight: bold;
	align-self: center;
	color: white;
	align-content: center;
}

.about-sec-p{
	margin-top: 2.5vh;
}

.about-pic {
	border-radius: 100%;
	height: 100%;
	border: 5px solid white;
}

@media all and (orientation: landscape) {
	.skills, .proj-con {
		margin-top: -5vh;
	}
}

@media all and (orientation: portrait) {
	.about-pic {
		height: 35vh;
	}

	.about-row {
		/* padding: 2vh !important; */
		/* margin-bottom: 5vh !important; */
	}

	.about-sec {
		font-size: calc(5vw);
		height: auto;
		border-width: 0;
		/* padding: 3vh !important; */
	}

	.skills {
		margin-top: 3vh;
		margin-bottom: 3vh;
	}

	.skills-col ul {
		font-size: calc(5vw);
	}

	.skills-head,
	.projs-head {
		font-size: calc(3vh + 3vmin);
	}

	.page-header {
		font-size: calc(5vw + 0.5vh);
		font-weight: bolder;
	}

	.about-me {
		align-self: center;
		height: auto;
	}

	.about-row2 {
		color: black;
		width: 80vw;
		/* height: 17.5vh; */
		vertical-align: middle;
		align-items: center;
		align-content: center;
		margin-top: calc(5vh + 10vw) !important;
		margin-bottom: calc(15vh + 5vw) !important;
		padding-bottom: 2.5vh !important;
	}

	.about-row2:first-child {
		margin-top: 5vh !important;
	}

	.about-row2:last-child {
		margin-bottom: 10vh !important;
	}

	.about-link {
		font-size: calc(5vw);
		text-decoration: none;
	}

	.icon-link {
		height: auto;
		font-size: 222%;
	}

	.icon-link p {
		font-size: calc(5vw);
		text-decoration: none;
	}

	.grid-header p {
		font-size: calc(2.5vh + 2vmin);
	}

	.project-title {
		font-size: calc(2.5vh + 2vmin);
	}

	.project {
		width: 80vw;
	}
}

.about-blue {
	background-color: cornflowerblue;
}

.about-header {
	width: 80vw;
}

